export const enum FilterKeyEnum {
  BranchCode = 'branchCode',
  ProviderId = 'providerId',
  CourseId = 'courseId',
  EmployeeId = 'employeeId',
  PlannableEmployeeId = 'plannableEmployeeId',
  ManagerId = 'managerId',
  ProjectId = 'projectId',
  SubDisciplineId = 'subDisciplineId',
  DisciplineId = 'disciplineId',
  LevelId = 'levelId',
  RequestStatus = 'requestStatus',
  MaritalStatus = 'maritalStatus',
  Icons = 'icon',
  Contract = 'contract',
  JobProfiles = 'jobProfile',
  EventType = 'eventType',
  ProjectLeadId = 'projectLeadId',
  ProjectType = 'projectType',
  Role = 'role',
  InspectionStatus = 'inspectionStatus',
  Licenses = 'licenseId',
  LeaveTypes = 'leaveType',
  FormType = 'formType',
}
