export const enum RoutesEnum {
  Login = 'login',
  ForgotPassword = 'wachtwoord-vergeten',
  ResetPassword = 'wachtwoord-resetten',
  UpdateTemporaryPassword = 'tijdelijk-wachtwoord-wijzigen',
  Compensation = 'vergoedingen',
  Dashboard = 'dashboard',
  Education = 'midt',
  Employees = 'medewerkers',
  Forms = 'formulieren',
  FormsWebview = 'formulieren-webview',
  Hours = 'urenlijsten',
  Leave = 'verlofaanvragen',
  News = 'nieuws',
  Orders = 'bestellingen',
  Projects = 'projecten',
  Advanced = 'geavanceerd',
  Downloads = 'downloads',
  Agenda = 'agenda',
  Inspections = 'inspecties',
  Planning = 'planning',
  PlanningViewer = 'planningsoverzicht',
}
