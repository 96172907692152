export const enum FilterEndpointEnum {
  Providers = 'top/providers',
  Courses = 'top/products',
  Levels = 'top/levels',
  Employees = 'employee',
  Projects = 'project',
  SubDisciplines = 'top/subdisciplines',
  Disciplines = 'top/disciplines',
  JobProfiles = 'top/function-profiles',
  KnowledgeTags = 'knowledge-tag',
  Licenses = 'top/ltos',
  Assignments = 'assignment',
  Forms = 'form',
}
