import { AssignmentLeaveTypeEnum, ConfigurationInterface, FeatureEnum, FeatureFlagEnum } from '@nutt/configuration';

export const configuration: Readonly<ConfigurationInterface> = {
  tenant: { brand: 'Grobbee', logoUrl: 'assets/logo.svg' },

  branches: [
    { name: 'Dalfsen', value: 'DAL' },
    { name: 'Dedemsvaart', value: 'DED' },
  ],

  features: [
    { feature: FeatureEnum.Employees, flags: { [FeatureFlagEnum.LeaveBalance]: true } },
    { feature: FeatureEnum.Inspections, isDisabled: true },
    {
      feature: FeatureEnum.Planning,
      flags: {
        [FeatureFlagEnum.DefaultWholeDay]: true,
      },
      metadata: {
        assignableLeaveTypes: [
          AssignmentLeaveTypeEnum.School,
          AssignmentLeaveTypeEnum.Sick,
          AssignmentLeaveTypeEnum.Leave,
          AssignmentLeaveTypeEnum.Doctor,
          AssignmentLeaveTypeEnum.UnScheduled,
        ],
        defaultStartOfWorkDay: '07:30',
        defaultEndOfWorkDay: '16:15',
      },
    },
    // The form feature is never properly tested beyond its pilot phase, so it is disabled for now.
    { feature: FeatureEnum.Forms, isDisabled: true },
    { feature: FeatureEnum.Education, isDisabled: true },
  ],
};
