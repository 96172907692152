import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { FILTER_LIMIT, ROVC_FILTER_LIMIT } from '../constants';
import {
  EventEnum,
  FilterEndpointEnum,
  FilterKeyEnum,
  InspectionStatusEnum,
  RequestStatusEnum,
  VisibilityEnum,
} from '../enums';
import { FilterOptionValueInterface } from '../interfaces';
import {
  mapEmployeeFilter,
  mapFilter,
  mapItemFilter,
  mapLevelsFilter,
  mapProjectFilter,
  mapSubDisciplineFilter,
} from '../mappers';
import { ApiService } from './api.service';
import {
  ApiRoleEnum,
  AssignmentLeaveTypeEnum,
  BranchConfigurationInterface,
  ConfigurationService,
  FeatureEnum,
  PermissionEnum,
  RoleEnum,
} from '@nutt/configuration';

const FILTER_REQUEST = { limit: FILTER_LIMIT };
const ROVC_FILTER_REQUEST = { limit: ROVC_FILTER_LIMIT };

@Injectable({ providedIn: 'root' })
export class FilterService {
  constructor(private configurationService: ConfigurationService, private apiService: ApiService) {}

  public getFilter$(key: string): Observable<FilterOptionValueInterface[]> {
    switch (key) {
      case FilterKeyEnum.BranchCode:
        return this.getBranchCodes$();
      case FilterKeyEnum.ProviderId:
        return this.getProviders$();
      case FilterKeyEnum.CourseId:
        return this.getCourses$();
      case FilterKeyEnum.EmployeeId:
        return this.getEmployees$();
      case FilterKeyEnum.PlannableEmployeeId:
        return this.getPlannableEmployees$();
      case FilterKeyEnum.ManagerId:
        return this.getManagers$();
      case FilterKeyEnum.ProjectId:
        return this.getProjects$();
      case FilterKeyEnum.SubDisciplineId:
        return this.getSubDisciplines$();
      case FilterKeyEnum.DisciplineId:
        return this.getDisciplines$();
      case FilterKeyEnum.LevelId:
        return this.getLevels$();
      case FilterKeyEnum.RequestStatus:
        return this.getRequestStatuses$();
      case FilterKeyEnum.MaritalStatus:
        return this.getMaritalStatuses$();
      case FilterKeyEnum.JobProfiles:
        return this.getJobProfiles$();
      case FilterKeyEnum.Contract:
        return this.getContracts$();
      case FilterKeyEnum.Icons:
        return this.getIcons$();
      case FilterKeyEnum.EventType:
        return this.getEventTypes$();
      case FilterKeyEnum.ProjectLeadId:
        return this.getProjectLeads$();
      case FilterKeyEnum.ProjectType:
        return this.getProjectTypes$();
      case FilterKeyEnum.Role:
        return this.getRoles$();
      case FilterKeyEnum.InspectionStatus:
        return this.getInspectionStatuses$();
      case FilterKeyEnum.Licenses:
        return this.getLicenses$();
      case FilterKeyEnum.LeaveTypes:
        return this.getLeaveTypes$();
      case FilterKeyEnum.FormType:
        return this.getFormTypes$();
      default:
        throw new Error('Filter not supported');
    }
  }

  public getBranchCodes$(): Observable<FilterOptionValueInterface[]> {
    return of(
      this.configurationService.getBranches().map((branch: BranchConfigurationInterface) => ({
        value: branch.value,
        label: branch.name,
      }))
    );
  }

  public getProjectTypes$(): Observable<FilterOptionValueInterface[]> {
    return of(this.configurationService.getMetadata(FeatureEnum.Projects)?.projectTypes ?? []);
  }

  public getProviders$(): Observable<FilterOptionValueInterface[]> {
    return this.apiService.get$(FilterEndpointEnum.Providers, ROVC_FILTER_REQUEST).pipe(map(mapItemFilter));
  }

  public getCourses$(): Observable<FilterOptionValueInterface[]> {
    return this.apiService.get$(FilterEndpointEnum.Courses, ROVC_FILTER_REQUEST).pipe(map(mapItemFilter));
  }

  public getFormTypes$(): Observable<FilterOptionValueInterface[]> {
    return this.apiService.get$(FilterEndpointEnum.Forms).pipe(map(mapFilter));
  }

  public getEmployees$(): Observable<FilterOptionValueInterface[]> {
    const request = { minimal_output: true };
    return this.apiService.get$(FilterEndpointEnum.Employees, request).pipe(map(mapEmployeeFilter));
  }

  public getPlannableEmployees$(): Observable<FilterOptionValueInterface[]> {
    const request = { minimal_output: true, plannable_only: true };
    return this.apiService.get$(FilterEndpointEnum.Employees, request).pipe(map(mapEmployeeFilter));
  }

  public getManagers$(): Observable<FilterOptionValueInterface[]> {
    const request = { minimal_output: true };
    return this.apiService.get$(FilterEndpointEnum.Employees, request).pipe(map(mapEmployeeFilter));
  }

  public getProjectLeads$(): Observable<FilterOptionValueInterface[]> {
    const request = { minimal_output: true, roles: [ApiRoleEnum.ProjectManager], active: true };
    // TODO : Filter for has role ProjectLead (add BE parameter first)
    return this.apiService.get$(FilterEndpointEnum.Employees, request).pipe(map(mapEmployeeFilter));
  }

  public getProjects$(requestPayload = {}): Observable<FilterOptionValueInterface[]> {
    const request = {
      minimal_output: true,
      ...requestPayload,
    };
    return this.apiService.get$(FilterEndpointEnum.Projects, request).pipe(map(mapProjectFilter));
  }

  public getDisciplines$(): Observable<FilterOptionValueInterface[]> {
    return this.apiService.get$(FilterEndpointEnum.Disciplines, ROVC_FILTER_REQUEST).pipe(map(mapItemFilter));
  }

  public getSubDisciplines$(): Observable<FilterOptionValueInterface[]> {
    return this.apiService
      .get$(FilterEndpointEnum.SubDisciplines, ROVC_FILTER_REQUEST)
      .pipe(map(mapSubDisciplineFilter));
  }

  public getLevels$(): Observable<FilterOptionValueInterface[]> {
    return this.apiService.get$(FilterEndpointEnum.Levels, ROVC_FILTER_REQUEST).pipe(map(mapLevelsFilter));
  }

  public getRequestStatuses$(): Observable<FilterOptionValueInterface[]> {
    return of([
      { value: RequestStatusEnum.Pending, label: RequestStatusEnum.Pending },
      { value: RequestStatusEnum.Granted, label: RequestStatusEnum.Granted },
      { value: RequestStatusEnum.Denied, label: RequestStatusEnum.Denied },
    ]);
  }

  public getMaritalStatuses$(): Observable<FilterOptionValueInterface[]> {
    // TODO : Create enum and mappers (api enum)
    return of([
      { value: 'unknown', label: 'Onbekend' },
      { label: 'Ongehuwd', value: 'not_married' },
      { label: 'Gehuwd', value: 'married' },
      { label: 'Samenwonend', value: 'cohabitating' },
      { label: 'Geregistreerd partnerschap', value: 'civil_unionship' },
    ]);
  }

  public getJobProfiles$(): Observable<FilterOptionValueInterface[]> {
    return this.apiService.get$(FilterEndpointEnum.JobProfiles, ROVC_FILTER_REQUEST).pipe(map(mapItemFilter));
  }

  public getContracts$(): Observable<FilterOptionValueInterface[]> {
    return of([
      // TODO : Use enum values
      { value: 'permanent', label: 'Vast' },
      { value: 'temporary', label: 'Tijdelijk' },
      { value: 'loan', label: 'Inlener' },
      { value: 'other', label: 'Overig' },
    ]);
  }

  // TODO : Use enums/types and map icons in data layer, not here
  public getIcons$(): Observable<FilterOptionValueInterface[]> {
    return of([
      { value: 'default', label: 'person' },
      { value: 'hammer', label: 'hardware' },
      { value: 'suitcase', label: 'work' },
      { value: 'lightbulb', label: 'lightbulb' },
      { value: 'window', label: 'window' },
      { value: 'flame', label: 'local_fire_department' },
      { value: 'headset', label: 'headset_mic' },
      { value: 'cone', label: 'radar' },
      { value: 'wrench', label: 'tools_wrench' },
      { value: 'helmet', label: 'engineering' },
      { value: 'tie', label: 'sell' },
      { value: 'compass', label: 'architecture' },
      { value: 'paint_roller', label: 'imagesearch_roller' },
      { value: 'lightning', label: 'bolt' },
      { value: 'storage', label: 'filter_none' },
      { value: 'water', label: 'water_drop' },
      { value: 'calculator', label: 'calculate' },
      { value: 'note_pencil', label: 'edit_note' },
      { value: 'hardware', label: 'construction' },
    ]);
  }

  public getRoles$(): Observable<FilterOptionValueInterface[]> {
    return of([
      { value: ApiRoleEnum.Administrator, label: RoleEnum.Administrator },
      { value: ApiRoleEnum.CommunicationManager, label: RoleEnum.CommunicationManager },
      { value: ApiRoleEnum.CompensationManager, label: RoleEnum.CompensationManager },
      { value: ApiRoleEnum.DownloadsManager, label: RoleEnum.DownloadsManager },
      { value: ApiRoleEnum.EducationManager, label: RoleEnum.EducationManager },
      { value: ApiRoleEnum.FormManager, label: RoleEnum.FormManager },
      { value: ApiRoleEnum.HoursManager, label: RoleEnum.HoursManager },
      { value: ApiRoleEnum.HumanResourceManager, label: RoleEnum.HumanResourceManager },
      { value: ApiRoleEnum.InspectionManager, label: RoleEnum.InspectionManager },
      { value: ApiRoleEnum.LeaveRequestManager, label: RoleEnum.LeaveRequestManager },
      { value: ApiRoleEnum.OrderManager, label: RoleEnum.OrderManager },
      { value: ApiRoleEnum.PlanningManager, label: RoleEnum.PlanningManager },
      { value: ApiRoleEnum.PlanningViewer, label: RoleEnum.PlanningViewer },
      { value: ApiRoleEnum.ProjectManager, label: RoleEnum.ProjectManager },
    ]);
  }

  public getPlanningOptions$(): Observable<FilterOptionValueInterface[]> {
    return of([
      { value: PermissionEnum.None, label: PermissionEnum.None },
      { value: PermissionEnum.ReadOnly, label: PermissionEnum.ReadOnly },
      { value: PermissionEnum.All, label: PermissionEnum.All },
    ]);
  }

  public getVisibilityOptions$(): Observable<FilterOptionValueInterface[]> {
    return of([
      { value: VisibilityEnum.None, label: VisibilityEnum.None },
      { value: VisibilityEnum.BranchOnly, label: VisibilityEnum.BranchOnly },
      { value: VisibilityEnum.All, label: VisibilityEnum.All },
    ]);
  }

  public getEventTypes$(): Observable<FilterOptionValueInterface[]> {
    return of([
      { label: EventEnum.Activity, value: EventEnum.Activity },
      { label: EventEnum.Anniversary, value: EventEnum.Anniversary },
      { label: EventEnum.Birthday, value: EventEnum.Birthday },
      { label: EventEnum.InformationEvening, value: EventEnum.InformationEvening },
      { label: EventEnum.Other, value: EventEnum.Other },
    ]);
  }

  public getInspectionStatuses$(): Observable<FilterOptionValueInterface[]> {
    return of([
      { value: InspectionStatusEnum.Open, label: InspectionStatusEnum.Open },
      { value: InspectionStatusEnum.Pending, label: InspectionStatusEnum.Pending },
      { value: InspectionStatusEnum.ActionRequired, label: InspectionStatusEnum.ActionRequired },
      { value: InspectionStatusEnum.Completed, label: InspectionStatusEnum.Completed },
    ]);
  }

  public getKnowledgeTags$(): Observable<FilterOptionValueInterface[]> {
    return this.apiService.get$(FilterEndpointEnum.KnowledgeTags, FILTER_REQUEST).pipe(map(mapFilter));
  }

  public getLicenses$(): Observable<FilterOptionValueInterface[]> {
    return this.apiService.get$(FilterEndpointEnum.Licenses, ROVC_FILTER_REQUEST).pipe(map(mapItemFilter));
  }

  public getLeaveTypes$(): Observable<FilterOptionValueInterface[]> {
    const configuredLeaveTypes: string[] = this.configurationService.getMetadata(
      FeatureEnum.Planning
    ).assignableLeaveTypes;

    return of([
      { value: AssignmentLeaveTypeEnum.School, label: AssignmentLeaveTypeEnum.School },
      { value: AssignmentLeaveTypeEnum.Sick, label: AssignmentLeaveTypeEnum.Sick },
      { value: AssignmentLeaveTypeEnum.Leave, label: AssignmentLeaveTypeEnum.Leave },
      { value: AssignmentLeaveTypeEnum.Doctor, label: AssignmentLeaveTypeEnum.Doctor },
      { value: AssignmentLeaveTypeEnum.ParentLeave, label: AssignmentLeaveTypeEnum.ParentLeave },
      { value: AssignmentLeaveTypeEnum.ReducedWorkingHours, label: AssignmentLeaveTypeEnum.ReducedWorkingHours },
      { value: AssignmentLeaveTypeEnum.Other, label: AssignmentLeaveTypeEnum.Other },
      { value: AssignmentLeaveTypeEnum.UnScheduled, label: AssignmentLeaveTypeEnum.UnScheduled },
    ]).pipe(
      map((filterOptionValues: FilterOptionValueInterface[]) =>
        filterOptionValues.filter((filterOptionValue: FilterOptionValueInterface) =>
          configuredLeaveTypes.includes(<string>filterOptionValue.value)
        )
      )
    );
  }
}
